import React, {Component} from 'react';
import {connect} from 'react-redux';
import styles from './ContactMeModal.module.css';
import {Modal, Button, Form} from 'react-bootstrap';
import * as actions from '../../store/actions/index';
// import { updateObject } from '../../shared/utility';

class ContactMeModal extends Component {

    state = {
        validated: true,
        email: '',
        comment: ''
    };

    handleClose = (event) => {
        this.props.onContactMeClick();
    };

    handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            console.log("Form is Not Valid.")
        } else {
            alert("Form is Valid!");
        }
        this.setState({validated: true});
    };

    updateEmailEntry = (event) => {
        this.setState({email: event.target.value});
    }

    updateCommentEntry = (event) => {
        this.setState({comment: event.target.value});
    }
    
    render() {
        let ModalBody = (
            <Modal 
                show={this.props.show} 
                onHide={this.handleClose}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Contact Chris</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please fill out the form below and I will get back to you shortly!</p>
                    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                required
                                type="email" 
                                placeholder="Enter email"
                                onChange={this.updateEmailEntry}
                            />
                            <Form.Text className="text-muted">
                                I'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicComment">
                            <Form.Label>Message/Comment</Form.Label>
                            <Form.Control
                                required 
                                as="textarea" 
                                rows={5} 
                                placeholder="Enter Your Message Here"
                                onChange={this.updateCommentEntry} 
                            />
                        </Form.Group>
                        <div className={styles.buttonGroup}>
                            <Button className={styles.submitButton} variant="primary" type="submit">
                                Submit
                            </Button>
                            <Button className={styles.closeButton} variant="danger" onClick={this.handleClose}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
        return ModalBody;
    };
};

const mapStateToProps = state => {
    return {
        show: state.general.show
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onContactMeClick: () => dispatch(actions.changeShowContactModal())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactMeModal);