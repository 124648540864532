import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../components/UI/Footer/Footer';
import Header from '../../components/UI/Header/Header';
import {Container} from 'react-bootstrap';
import ContactModal from '../../components/ContactMeModal/ContactMeModal'

class Layout extends Component {
    render() {
        return (
        <Container fluid>
            <Header />
            <ContactModal 
                show={this.props.show} 
            />
            {this.props.children}
            <Footer />
        </Container>
        );
    }    
}

const mapStateToProps = state => {
    return {
        show: state.general.show
    };
}

export default connect(mapStateToProps)(Layout);