import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from '../../hoc/asyncComponent/asyncComponent';
import Layout from '../Layout/Layout';
import styles from './App.module.css';

const asyncHomePage = asyncComponent(() => {
  return import('../Home/Home');
});

const asyncMyNotesPage = asyncComponent(() => {
  return import('../MyNotes/MyNotes');
});

const asyncResumePage = asyncComponent(() => {
  return import('../Resume/Resume');
});

const asyncMyDashboardsPage = asyncComponent(() => {
  return import('../MyDashboards/MyDashboards');
});

class App extends Component {
  render() {
    return (
      <div className={styles.App}>
        <Layout>
          <Switch>
            <Route path="/" exact component={asyncHomePage} />
            <Route path="/mynotes" exact component={asyncMyNotesPage} />
            <Route path="/resume" exact component={asyncResumePage} />
            <Route path="/mydashboards" exact component={asyncMyDashboardsPage} />
            <Redirect to="/" />
          </Switch>
        </Layout>
      </div>
    );
  };
}

export default withRouter(connect()(App, true));