import React, {Component} from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styles from './NavigationBar.module.css';
import * as actions from '../../../store/actions/index';
import {connect} from 'react-redux';

class NavigationBar extends Component {
    handleSelect = (eventKey) => {
        if(eventKey === "contactMe"){
            this.props.onContactMeClick();
        } else if (eventKey === "login") {
            this.login();
        }
    };

    login = () => {
        
    };

    render() {
        let navBar = (
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto" onSelect={this.handleSelect}>
                <Nav.Link className={styles.MainNavLinks} href="/resume">Resume</Nav.Link>
                <Nav.Link className={styles.MainNavLinks} eventKey="contactMe">Contact Me</Nav.Link>
                <NavDropdown className={styles.MainNavLinks} title="Projects" id="basic-nav-dropdown">
                    <NavDropdown.Item className={styles.MenuTitle} disabled>Websites/Apps</NavDropdown.Item>
                    <NavDropdown.Item href="https://www.hikingthepacificnorthwest.com/">Hiking the Pacific Northwest</NavDropdown.Item>
                    <NavDropdown.Item href="https://www.bestbtcinvestments.com/">Best BTC Investments</NavDropdown.Item>
                    <NavDropdown.Item href="https://www.space-expedition.com/">Space Expedition</NavDropdown.Item>
                    <NavDropdown.Item href="https://www.collectorofallcards.com/">Collector of All Cards</NavDropdown.Item>
                    <NavDropdown.Item href="https://www.dragon-development-solutions.com/">Dragon Development Solutions</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className={styles.MenuTitle} disabled>Apps</NavDropdown.Item>
                    <NavDropdown.Item href="https://www.dragon-development-solutions.com/apps/dragon-runner.php">Dragon Runner</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className={styles.MenuTitle} disabled>Other Projects</NavDropdown.Item>
                    <NavDropdown.Item href="https://www.dragon-grid.chrissmithwebmaster-developer.com/">Dragon Grid System</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link className={styles.MainNavLinks} href="/mynotes">My Notes</Nav.Link>
                <Nav.Link className={styles.MainNavLinks} href="/mydashboards">My Dashboards</Nav.Link>
                <Nav.Link className={styles.MainNavLinks} eventKey="login">Login</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        );

        return (
            <Navbar expand="lg" fixed="top" className={styles.navbar}>
                <Navbar.Brand className={styles.NavBarTitle} href="/">Chris Smith's Environment</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {navBar}
            </Navbar>
        );
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onContactMeClick: () => dispatch(actions.changeShowContactModal())
    };
}

export default connect(null, mapDispatchToProps)(NavigationBar);