import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const intitalState = {
    show: false
};

const changeShowOnContactModal = (state) => {
    const newShowState = !state.show;
    return updateObject(state, { show: newShowState });
};

const reducer = (state = intitalState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_MODAL: return changeShowOnContactModal(state);
        default: return state;
    };
};

export default reducer;