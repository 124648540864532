import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import {connect} from 'react-redux';

const Header = () => {
    return (
        <NavigationBar />
    );
};

export default connect()(Header);