import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
    let date = new Date();
    let year = date.getFullYear();
    return (
        <footer className={styles.Footer}>
            <p className={styles.FooterParagraph}>Copyright &copy; 2007-{year} Chris Smith - Webmaster, Developer, and Engineer</p>
        </footer>
    );
};

export default Footer;